<template>
	<div class="box">
		<h2>{{ $t('gynecologie.info_contact.liste_tiers') }}</h2>
		<b-table
			striped hover
			:items="tiers"
			:fields="columns_tiers"
		>
			<template v-slot:cell(tiers_rs)="data">
				<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: data.item.tiers_id }}">
					{{ data.item.tiers_rs }}
				</router-link>
			</template>
			<template v-slot:cell(phones_number)="data">
				<span v-html="cleanHTML(data.item.phones_number)"></span>
			</template>
		</b-table>

		<h2>{{ $t('gynecologie.info_contact.lieu_stationnement') }}</h2>
		<template v-if="lieu_stationnement">
			<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: lieu_stationnement.tiers_id }}">
				{{ lieu_stationnement.tiers_rs }}
			</router-link>
			({{ lieu_stationnement.phones_number }})
		</template>
		<template v-else>{{ $t('global.aucun') }}</template>

		<h2 class="mt-4">{{ $t('gynecologie.info_contact.liste_contact') }}</h2>
		<b-table
			striped hover
			:items="contacts"
			:fields="columns_contacts"
		>
			<template v-slot:cell(contact_name)="data">
				{{ data.item.contact_civility }} {{ data.item.contact_firstname }} {{ data.item.contact_lastname }}
			</template>
			<template v-slot:cell(phones_number)="data">
				<span v-html="cleanHTML(data.item.phones_number)"></span>
			</template>
			<template v-slot:cell(fonctions)="data">
				<span v-html="cleanHTML(data.item.fonctions)"></span>
			</template>
		</b-table>
	</div>
</template>

<script type="text/javascript">
import Horse from "@/mixins/Horse.js"
import xss from 'xss'

export default {
	name: 'InfoContacts',
	mixins: [Horse],
	props: ['horse_id'],
	data () {
		return {
			columns_tiers: [
				{
					key:'tiers_rs', 
					label: this.getTrad('gynecologie.info_contact.rs')
				},
				{
					key:'phones_number',
					label: this.getTrad('gynecologie.info_contact.phone')
				}
			],
			tiers: [],
			lieu_stationnement: {},
			columns_contacts: [
				{
					key:'contact_name', 
					label: this.getTrad('gynecologie.info_contact.contact_name')
				},
				{
					key:'phones_number',
					label: this.getTrad('gynecologie.info_contact.phone'),
				},
				{
					key:'fonctions',
					label: this.getTrad('gynecologie.info_contact.fonction'),
				}
			],
			contacts: []
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {
			const tiers = await this.loadTiersHeavyCurrentPart(this.horse_id)
			this.tiers = tiers.map(tier => {
				return {
					tiers_id: tier.tiers.tiers_id,
					tiers_rs: tier.tiers.tiers_rs,
					phones_number: tier.tiers.phones ? tier.tiers.phones.map(phone => phone.phone_combine).join('<br>') : ''
				}
			})

			this.lieu_stationnement = await this.getHorseLieuStationnement(this.horse_id)

			const contacts = await this.loadHorseContacts(this.horse_id)
			this.contacts = contacts.map(con => {
				return {
					contact_lastname: con.contact.contact_lastname,
					contact_firstname: con.contact.contact_firstname,
					contact_civility: con.contact.contact_civility,
					fonctions: con.fonctions.map(fct => this.getTrad(fct.fonction_label)).join('<br>'),
					phones_number: con.contact.phones ? con.contact.phones.map(phone => phone.phone_combine).join('<br>') : ''
				}
			})
		},

		cleanHTML(input) {
			return xss(input)
		}
	},
}

</script>